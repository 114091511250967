<template>
  <div class="content-container">
    <span class="title-login">{{
      firstReset ? "Creare parolă nouă" : "Resetare parolă"
    }}</span>
    <form
      v-if="!invalidCode"
      @submit.prevent="pushPassword"
      autocomplete="new-password"
      class="forgot_password"
    >
      <div class="form-group has-feedback has-preloader">
        <label for="email" class="custom-label">Utilizator</label>
        <input
          v-model="emailInput"
          id="email"
          :disabled="Username"
          type="email"
          class="form-control custom-input"
          name="email"
          value=""
          autofocus=""
          autocapitalize="false"
          autocorrect="false"
          autocomplete="new-password"
        />
      </div>
      <div class="form-group has-feedback has-preloader">
        <label for="password" class="custom-label">Parola nouă</label>
        <input
          v-model="pass"
          @copy.prevent=""
          @paste.prevent=""
          id="password"
          :type="showPass ? 'text' : 'password'"
          class="form-control custom-input"
          name="password"
          value=""
          autofocus=""
          autocapitalize="false"
          autocorrect="false"
          autocomplete="new-password"
        />
        <div
          class="spinner"
          style="display: inline;cursor: pointer;"
          @click="showPass = !showPass"
        >
          <i :class="`fas fa-eye${showPass ? '-slash' : ''}`"></i>
        </div>
        <div
          v-tooltip.bottom="passwordStrengthMessage[passwordStrength] || ''"
          class="StrengthLevels"
          :class="`Level${passwordStrength}`"
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="form-group has-feedback has-preloader">
        <label for="password_confirm" class="custom-label"
          >Confirmă parola</label
        >

        <input
          v-model="passConfirm"
          id="password_confirm"
          type="password"
          class="form-control custom-input"
          name="password_confirm"
          value=""
          autofocus=""
          autocapitalize="false"
          autocorrect="false"
          autocomplete="new-password"
        />
      </div>
      <div class="form-group">
        <div class="text-center">
          <input
            type="submit"
            id="send_but_forgot"
            class="submit-btn white-font"
            :value="firstReset ? 'Stabilește parola' : 'Setează parola'"
            autocomplete="new-password"
            autocapitalize="false"
            autocorrect="false"
          />
        </div>
      </div>
    </form>

    <h2 v-else style="display: flex; justify-content: center;">
      Parola a fost deja stabilită.
    </h2>
  </div>
</template>

<script>
import { PASSWORD_RESTORE, CHECK_PASSWORD_TOKEN } from "@/api.js";

export default {
  data() {
    return {
      invalidCode: false,
      email: "",
      pass: "",
      passConfirm: "",
      showPass: false,
      laoding: false,
      firstReset: this.$route.meta.firstReset,
      passwordStrengthMessage: {
        0: "Parola trebuie să conțină minim 8 caractere",
        1: "Parola este slabă",
        2: "Parola este medie",
        3: "Parola este sigură",
      },
    };
  },

  computed: {
    token() {
      return this.$route.params.token || null;
    },
    Username() {
      const x = this.$route.params.email;
      return x && x.length > 2 ? x : null;
    },
    passwordStrength() {
      const strongPasswordRegex = new RegExp(
        "^(?=.{9,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$",
        "g"
      );
      const mediumPasswordRegex = new RegExp(
        "^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$",
        "g"
      );
      const enoughPasswordRegex = new RegExp("(?=.{8,}).*", "g");

      const x = this.pass;
      if (!x) return false;

      if (strongPasswordRegex.test(x)) return 3;
      if (mediumPasswordRegex.test(x)) return 2;
      if (enoughPasswordRegex.test(x)) return 1;

      return 0;
    },
    emailInput: {
      get(x) {
        return this.email;
      },
      set(val) {
        if (!this.Username) {
          this.email = val;
        }
      },
    },
  },
  methods: {
    pushPassword() {
      if (this.laoding) return;
      const errors = [];
      const a = (e) => e?.trim();
      const basicPass = /((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

      if (!this.Username && this.emailInput.length < 3) {
        errors.push("E-mail-ul nu este introdus corect.");
      }
      if (!this.passwordStrength || !basicPass.test(this.pass)) {
        errors.push(
          "Parola trebuie să conțină minim câte o minusculă, majusculă, cifră și un caracter special."
        );
      }
      if (a(this.pass) !== a(this.passConfirm)) {
        errors.push("Parolele nu coincid.");
      }

      if (this.passwordStrength !== 3) {
        errors.push(this.passwordStrengthMessage[this.passwordStrength]);
      }

      if (errors.length) {
        this.$toastr.w(errors.slice(-1));
        return;
      }

      this.laoding = true;
      PASSWORD_RESTORE({
        email: this.emailInput,
        password: this.pass,
        token: this.token,
      })
        .then((res) => {
          if (res?.meta?.HttpStatusCode === 200) {
            this.$toastr.s(
              `Parola a fost ${
                this.firstReset == "unconfirmed"
                  ? "creată"
                  : this.firstReset
                  ? "schimbată"
                  : "resetată"
              }.`
            );
            this.logout();
          } else {
            this.$toastr.e("Setarea parolei a eșuat.");
            this.goToHomePage();
          }
          this.laoding = false;
        })
        .catch((err) => {
          this.$toastr.e(err);
          this.goToHomePage();
          this.laoding = false;
        });
    },
  },
  created() {
    if (this.token?.length !== 36) {
      this.goToHomePage();
    }

    this.email = this.Username;

    this.setLoad(true);
    CHECK_PASSWORD_TOKEN({
      email: this.emailInput,
      password: this.pass,
      token: this.token,
    })
      .then((res) => {

        if (res.statusCode && res.statusCode !== 200) {
          this.invalidCode = true;
        }
        this.setLoad();
      })
      .catch((err) => {
        this.setLoad();
        this.invalidCode = true;
        this.$toastr.w(err);
      });
  },
};
</script>

<style>
.password-input {
  background-color: transparent;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 12px;
  width: 100%;
  border: 1px solid #999;
}
</style>
